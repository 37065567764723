#slide-menu {
  position: fixed;

  -webkit-box-sizing: border-box;

          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  float: left;
  width: 100%;
  max-width: 284px;
  height: 100%;
  background: $grayBack;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 10;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;

  @media (max-width: $breakpoin1024) {
    max-width: 200px;
    right: -200px;
  }
  @media (max-width: $breakpoin768) {
    max-width: 100vw;
    right: -100vw;
  }
  h2 {
    cursor: default;
    @media (max-width: $breakpoin1024) {
      font-size: calc(var(--mainFont) + 8px);
    }
  }
.content{
  padding-bottom: 10vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ul {
    display: block;
    margin: 40px;
    padding: 0;
    list-style: none;
    opacity: 0.5;
    -webkit-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;

    @media (max-height: 450px){
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
      justify-content: center;
      align-items: center;
    }

    li {
      padding: 6px 0;
      cursor: pointer;

      &:hover {
        color: $blueColor;
      }
    }

    li.sep {
      margin-top: 7px;
      padding-top: 14px;
      border-top: 1px solid lighten(#556270, 10%);
    }
  }
  img{
    height: 20vh;

    @media (max-width: $breakpoin1024) { 
     height: 18vh;
     //width: 80%;
    }

    @media (max-width: $breakpoin768) { 
      //display: none;
     height: 30vh;
     //width: 100%;
    }
    @media (max-height: 700px){
      display: none;
    }
  }
}

  .menu-trigger {
    position: absolute;
    bottom: 50px;
    left: -120px;
    width: 70px;
    height: 70px;
    background: $blueColor;
    cursor: pointer;
    //border-radius: 5px;
    -webkit-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
    border-radius: 50%;
    -webkit-animation: jumper 5s ease-in-out 2s infinite;
            animation: jumper 5s ease-in-out 2s infinite;
    &:hover {
      background: linear-gradient(90deg, $whiteColor, $backGlass);
  backdrop-filter: blur(5px);
      border-radius: 50%;
    }
    img {
      border-radius: 20px;
      width: inherit;
      height: inherit;
    }

    @media (max-width: $breakpoin768) {
      bottom: 30px;
      height: 50px;
      width: 50px;
      left: -100px;
    }

    @media (max-width: $breakpoin480) {
      left: -100px;
    }

    @media (max-width: 320px) {
      left: -90px;
    }
  }

  .phone{
    position: absolute;
    left: -89vw;
    bottom: 30px;
    display: none;
    width: 50px;
    height: 50px;

    &::before, &::after{
      content: ''; // Нужно для отображения псевдокласса
  position: absolute;
  border: 2px solid rgb(25, 209, 38); // Ширину обводки можете выбрать сами
  left: -20px;
  opacity: 0;
  right: -20px;
  top: -20px;
  bottom: -20px;
  border-radius: 50%;
  animation: pulse 2.5s linear infinite; // Указываем название анимации, время, тип, и нужно ли её повторять
    }
    &::after{
      animation-delay: 1.25s;
    }

    @media (max-width: $breakpoin480) { 
     display: block;
    }
   
    svg{
    fill: rgb(25, 209, 38);
    width: 100%;
    height: 100%;
    }
  }
}


.menu-right{
  top: 0;
  right: -284px;
}



.menu-active {
  right: 0;
}

.jump-done {
  -webkit-animation: rotate 5s ease-in-out infinite !important;
          animation: rotate 5s ease-in-out infinite !important;
}

.active-trigger {
  -webkit-animation: none !important;
          animation: none !important;
  background: $blueColor !important;

  &:hover {
    background: linear-gradient(90deg, $whiteColor, $backGlass) !important;
backdrop-filter: blur(5px) !important;}

  @media (max-width: $breakpoin768) {
    bottom: 30px !important;
    left: 87vw !important;
    z-index: 12;
  }
  @media (max-width: $breakpoin480) {
    left: 76.5vw !important;
  }
  @media (max-width: 320px) {
    left: 72vw !important;
  }
}