.table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  margin-top: 30px;
  @media (max-width: $breakpoin1440) { 
    grid-template-columns: repeat(3, 1fr)
  }
  @media (max-width: $breakpoin1024) { 
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $breakpoin768) { 
    grid-template-columns: repeat(1, 1fr);
  }
  a{
    margin-bottom: 0;
    @media (max-width: $breakpoin768) { 
     margin-bottom: 20px;
    }
  }
}