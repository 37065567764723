.settings {
  padding: 0 15% 0px 15% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(to bottom, $whiteColor, rgb(70, 70, 70)) ;

  @media (max-width: $breakpoin1024) {
    padding: 0 5% 30px 5% !important;
  }

  h1 {
    @media (max-width: $breakpoin480) {
      font-size: calc(var(--mainFont) + 13px);
    }
  }
}
