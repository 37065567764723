.hero {
  background: url('../pics/hero.jpg');

  background-size: cover;
  background-position: 100%;

  @media (max-width: $breakpoin480) { 
   display: flex;
  justify-content: center;
  align-items: center;
  }
  

  @media (max-width: 1439px) {
    background-position: 80%;
  }
  .text {
    cursor: default;
    margin-top: 15vh;
    margin-left: 40px;
    width: 35vw;
    color: $whiteColor;
    background-color: rgb(70, 70, 70);
    border: 2px solid $blueColor;
    padding: 30px 25px;

    @media (max-width: $breakpoin1440) { 
     width: 50%;
    }
    @media (max-width: $breakpoin768) {
      width: 60%;
      margin-left: 0;
      margin-top: 0;
    }
    @media (max-width: $breakpoin480) {
      width: 100%;
    }
    @media (max-width: 360px) {
      padding: 0 10px;
    }
    .sec {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    h1{
      @media (max-width: $breakpoin480) { 
       font-size: calc(var(--mainFont + 20px));
      }

      @media (max-width: 360px) { 
       font-size: calc(var(--mainFont) + 1px);
      }
    }
  }
}