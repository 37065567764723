.loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transition: all .09s ease-out;
}

.loader--hide {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.loader__loader, .preloader__percents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader__percents {
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
}





@keyframes ldio-zgxx64th47 {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}

.ldio-zgxx64th47 div {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 10px solid #695be1;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-zgxx64th47 div {
  animation: ldio-zgxx64th47 1s linear infinite;
  top: 100px;
  left: 100px
}

.loadingio-spinner-rolling-wopxwk46ahe {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: rgba(241, 242, 243, 0);
}

.ldio-zgxx64th47 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-zgxx64th47 div {
  box-sizing: content-box;
}