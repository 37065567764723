.back-links {
  display: flex;
  justify-content: space-around;
  a {
    width: 300px;
    height: 80px;

    @media (max-width: $breakpoin768) {
      width: 150px;
      height: 120px;
    }
    @media (max-width: $breakpoin480) {
      height: auto;
      width: 120px;
    }
    @media (max-width: 350px) {
      width: 100px;
    }
  }
}
