.disappear {
  animation: fade 1s reverse ease-in-out;
}

.appear {
  animation: fade 1s ease-in-out; 
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes starShine {
  0% {
    fill: black;
  }
  50% {
    //fill: rgb(212, 151, 21);
    fill: $blueColor;
  }
}

@keyframes starShine {
  0% {
    fill: black;
  }
  50% {
    //fill: rgb(212, 151, 21);
    fill: $blueColor;
  }
}

@keyframes jumper {
  0%,10%, 20%{
    transform: scale(1);
  }
  5%,15%{
    transform: scale(2);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-webkit-keyframes hi {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes hi {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}