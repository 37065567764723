// .about-us{

// }
.about-us {
  padding: 14vh 15% 10vh 15% !important;

  .about-content {
    @media (max-width: $breakpoin768) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }
    h2 {
      margin: 7vh 0 3vh 0;
    }
    .contacts {
      @media (max-width: $breakpoin768) {
        text-align: center;
      }

      a {
        text-decoration: none;
        color: $blueColor;
        &:hover {
          color: $whiteColor;
        }
      }
    }
  }
}
