.feedback-form {
  min-height: 50vh;
  margin: 10vh auto 2vh 5vw;
  max-width: 80vh;
 //background: rgba(255, 255, 255, 0.3);
  background:rgba(196, 199, 204, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.feedback-form .text {
  text-align: center;
  padding: 0;

  @media (max-width: $breakpoin360) {
    font-size: 20px;
  }
}
.feedback-form form {
  padding: 30px 0 0 0;
}
.feedback-form form .form-row {
  display: flex;
  margin: 32px 0;
}
form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea {
  height: 70px;
}
.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  outline: none;
  background-color: transparent;
}
.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
}
.textarea textarea {
  resize: none;
  padding-top: 10px;
  padding-bottom: 0;
}
.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}
.textarea label {
  width: 100%;
  bottom: 40px;
  //background: #fff;
}
.input-data .underline {
  position: absolute;
  bottom: -2px;
  height: 2px;
  width: 100%;
}
.input-data.textarea .underline {
  bottom: -12px;
}
.input-data .underline:before {
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  background: $blueColor;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}

.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
}
.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background-color: $blueColor;
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner {
  left: 0;
}
.submit-btn .input-data input {
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
@media (max-width: 1024px) {
  .feedback-form form .form-row {
    display: block;
  }
  form .form-row .input-data {
    margin: 35px 0 !important;
  }
}
