.article-list,
.services-list {
  padding: 0 15% 10vh 15% !important;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, $whiteColor, rgb(70, 70, 70));

  @media (max-width: $breakpoin1024) {
    padding: 0 5% 10vh 5% !important;
  }

  @media (max-width: $breakpoin768) {
    padding: 0 5% 10vh 5% !important;
  }

  h1 {
    @media (max-width: $breakpoin480) {
      font-size: calc(var(--mainFont) + 13px);
    }
  }

  a {
    height: 4em;
    margin-bottom: 50px;
    @media (max-width: $breakpoin480) {
      height: auto;
    }
  }
}

.article-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15% 30px 15%;
  text-align: justify;
  @media (max-width: $breakpoin480) {
    margin: 0 5% 30px 5%;
    font-size: calc(var(--mainFont) - 5px);
  }
  @media (max-width: 350px) {
    font-size: calc(var(--mainFont) - 8px);
  }

  h1 {
    text-align: center;
    @media (max-width: $breakpoin480) {
      font-size: calc(var(--mainFont) + 13px);
    }
    @media (max-width: 350px) {
      font-size: calc(var(--mainFont) + 10px);
    }
  }

  h2 {
    text-align: center;
  }

  p {
    text-indent: 70px;
    margin-top: 0;
    @media (max-width: $breakpoin480) {
      text-indent: 20px;
    }
  }
  span {
    text-align: center;
  }

  a {
    height: auto;
    margin: 0;
  }

  ol {
    li {
      margin-bottom: 30px;
    }
  }

  .enter-img {
    margin: 50px 0;
  }

  .article-img {
    position: relative;
    height: 500px;

    @media (max-width: $breakpoin1440) {
      height: 300px;
      width: 500px;
    }
    @media (max-width: $breakpoin1024) {
      display: none;
    }
  }

  .article-img-low {
    position: relative;
    height: 400px;
    width: 600px;
    display: none;
    @media (max-width: $breakpoin1024) {
      margin: auto;
      display: block;
    }
    @media (max-width: $breakpoin480) {
      height: 300px;
      width: 400px;
    }
    @media (max-width: 400px) {
      height: 200px;
      width: 300px;
    }
  }
}
