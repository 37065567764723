.settings-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
gap: 50px;

  .setting-descr{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $breakpoin480) { 
     flex-direction: column;
     gap: 20px;
    }

    .setting-change{
      cursor: pointer;
    }
  }
}