.how-to{
  span{
    display: flex;
    justify-content: center;
  }
  ol{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 60px;
      list-style: none;

      @media (max-width: $breakpoin1024) { 
       flex-wrap: wrap;
       gap: 20px;
      }
      @media (max-width: $breakpoin768) { 
       gap:0;
      }
      @media (max-width: $breakpoin480) { 
       padding: 0;
      }
  }
}