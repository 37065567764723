.header {
  // position: fixed;
  //position: sticky;
//  top: 0;
  overflow-y: hidden;
  // -webkit-box-sizing: border-box;
  //         box-sizing: border-box;
  // top: 0px;
  // left: 50%;
  // -webkit-transform: translate(-50%);
  //     -ms-transform: translate(-50%);
  //         transform: translate(-50%);
  //width: 100vw;
  //position: relative;
  //top: 14vh;
  height: 14vh;
  background-color: $blueColor;
  color: $whiteColor;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 77px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-x: hidden;
  z-index: 10;

  // @media (max-width: $breakpoin1024) {
  //   height: 150px;
  // }
  @media (max-width: $breakpoin768) {
    padding: 10px 30px;
    height: 10vh;
  }
  @media (max-width: $breakpoin480) {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    //margin-top: 20px;
  }
  @media (max-height: 700px){
    height: 13vh;
  }

  @media (max-height: 450px){
    height: 15vh;
  }

  @media (max-height: 320px){
    font-size: calc(var(--mainFont) - 10px);
  }

  .logo-txt {
    cursor: pointer;
    background-color: $blueColor;
    border-radius: 5px;
    padding: 15px;
  }

  .number {
    background-color: $blueColor;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    @media (max-width: $breakpoin480) {
      display: none;
    }
  }
}