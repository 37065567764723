//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import './styles/vars';
@import './styles/global';
@import './styles/animations';

body {
  background: $bodyColor;
  max-width: 100vw;
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: var(--mainFont);
  font-family: 'Roboto', sans-serif;
  transition: padding-left 300ms;
}

.locked {
  background-color: grey;
}

//site-layout
@import './00_site_layout/layout.scss';
@import './00_site_layout/Header/header.scss';
@import './00_site_layout/Sidebar/sidebar.scss';
@import './00_site_layout/Footer/footer.scss';

//pages
//main page
@import './01_pages/HomePage/styles/faq.scss';
@import './01_pages/HomePage/styles/cards.scss';
@import './01_pages/HomePage/styles/hero.scss';
@import './01_pages/HomePage/styles/feedback.scss';
@import './01_pages/HomePage/styles/pluses.scss';
@import './01_pages/HomePage/styles/production.scss';

@import './01_pages/AboutUsPage/about.scss';
@import './01_pages/LegalNoticePage/legal-notice.scss';

//not-found
@import './01_pages/NotFoundPage/not-found.scss';

//settings
@import './01_pages/SettingsPage/settings.scss';

//modules styles
@import './02_samples/ModuleSample/module.scss';
@import './02_samples/ArticlePageSample/article.scss';

//components styles
@import './03_components/PreLoader/preloader.scss';
@import './03_components/LoaderInside/loader-inside.scss';
@import './03_components/ArticleComponents/HowTo/how-to.scss';
@import './03_components/List/accordion-list.scss';
@import './03_components/List/card-list.scss';
@import './03_components/List/settings-list.scss';
@import './03_components/List/table.scss';
@import './03_components/Tabs/tabs.scss';
@import './03_components/FeedbackForm/feedback-form.scss';

//ui styles
@import './04_ui_kit/AccordionItem/accordion-item.scss';
@import './04_ui_kit/BackLinksArticles/back-links.scss';
@import './04_ui_kit/Card/card.scss';
@import './04_ui_kit/CardFace/card-face.scss';
@import './04_ui_kit/SettingsItem/settings-item.scss';
@import './04_ui_kit/Modal/modal.scss';
