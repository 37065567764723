.module{
position: relative;
min-height: 100vh;
padding: 30px;
z-index: 5;
box-sizing: border-box;
width: 100vw;

.title {
  background: linear-gradient(90deg, $whiteColor, $backGlass);
  backdrop-filter: blur(5px);
  max-width: 500px;
  padding: 15px;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  font-size: calc(var(--mainFont) + 20px);
  text-shadow: 5px 5px 5px $textShadow;
  pointer-events: none;
  @media (max-width: $breakpoin480) {
    font-size: var(--mainFont);
  }
}
}