.face {
  margin-top: 10%;
  height: 400px;
  width: 270px;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  position: relative;
  background: -o-linear-gradient(315deg, $whiteColor, $backGlass);
  background: linear-gradient(135deg, $whiteColor, $backGlass);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
  border-top: 2px solid $borderTopLeft;
  border-left: 2px solid $borderTopLeft;
  border-right: 2px solid $borderBottomRight;
  border-bottom: 2px solid $borderBottomRight;
  -webkit-box-shadow: 5px 5px 5px $boxShadow;
  box-shadow: 5px 5px 5px $boxShadow;
  font-size: calc(var(--mainFont) - 5px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10%;
  cursor: default;
  transition: all 1s;
  overflow: hidden;
  @media (max-width: $breakpoin2560) {
    margin-top: 5%;
  }
  @media (max-width: $breakpoin1920) {
    margin-top: 7%;
  }
  @media (max-width: $breakpoin1440) {
    margin-top: 5%;
  }
  @media (max-width: $breakpoin1024) {
    overflow-y: scroll;
    padding-bottom: 20px;
  }
  .face-img{
   height: 40%;
   width: 80%;
   display: flex;
   align-items: center;
   justify-content: center;

   img{
    height: 100%;
    width: 100%;
   }
  }

  .inner {
    height: 30%;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1.5em 0;
  }
  .button{
    height: 10%;
  }
}
