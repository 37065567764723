.feedback{
  //background-color: $whiteColor;
  background: url('../pics/service-man.jpg');
  background-size: cover;
  @media (max-width: $breakpoin1024) { 
    background-position: 10%;
  }
  @media (max-width: $breakpoin768) { 
   background-position: 45%;
  }
  @media (max-width: $breakpoin480) { 
   background-position: 60%;
  }
  @media (max-width: $breakpoin360) { 
    background-position: 65%;
   }
}