.modal{
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 30px;
  background-color: $grayBack;
  width: 50vw;
  height: 50vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 3%;
  box-sizing: border-box;

  @media (max-width: $breakpoin768) { 
   justify-content: center;
   text-align: center;
   gap: 20px;
   height: auto;
  }
  @media (max-width: $breakpoin480) { 
   width: 80vw;
  }

  .modal-input{
    height: 30px;
    width: 50%;
    @media (max-width: $breakpoin480) { 
     height: 50px;
     width: 70%;
    }
  }
.buttons{
  width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media (max-width: $breakpoin768) { 
     gap: 20px; 
     flex-direction: column;
    }

    .accept{
      border: 2px solid green;
      &:hover{
        background-color: green;
      }
      
    }
    .default{
        border: 2px solid rgb(223, 203, 110);
        &:hover{
          background-color: rgb(223, 203, 110);;
        }}
        .cancel{
          border: 2px solid rgb(238, 98, 98);
        &:hover{
          background-color: rgb(238, 98, 98);;
        }
        }
  }
}