.home-cards {
  background: url('../pics/gears.jpg');
  background-size: cover;
  z-index: 2;

  @media (max-width: $breakpoin768) {
    height: auto !important;
  }

  img {
    position: absolute;
    height: 100px;
  }

  .production-img {
    position: absolute;
    top: 0;
    left: 47%;

    .gear1 {
      top: 20px;
      right: -87px;
      -webkit-animation: rotate 10s infinite linear;
      animation: rotate 10s infinite linear;
    }
    .gear2 {
      top: 70px;
      right: -15px;

      -webkit-animation: rotate 10s infinite linear;

      animation: rotate 10s infinite linear;
      animation-direction: reverse;
    }
    .gear3 {
      top: 118px;
      right: -88px;
      -webkit-animation: rotate 10s infinite linear;
      animation: rotate 10s infinite linear;
    }
  }

  .wrench {
    position: absolute;
    height: 150px;
    top: 15%;
    left: 20%;
    -webkit-animation: rotate 5s infinite linear;
    animation: rotate 5s infinite linear;
  }

  .service {
    svg {
      position: absolute;
      height: 40px;
      width: 40px;
      top: 35%;
      -webkit-animation: starShine 3s infinite linear;
      animation: starShine 3s infinite linear;
    }
    #svg1 {
      left: 3.5%;
    }
    #svg2 {
      left: 23.5%;
      -webkit-animation-delay: 400ms;
      animation-delay: 400ms;
    }
    #svg3 {
      left: 43.5%;
      -webkit-animation-delay: 800ms;
      animation-delay: 800ms;
    }
    #svg4 {
      left: 63.5%;
      -webkit-animation-delay: 1200ms;
      animation-delay: 1200ms;
    }
    #svg5 {
      left: 83.5%;
      -webkit-animation-delay: 1600ms;
      animation-delay: 1600ms;
    }
  }
}
