.accordion {
  margin: 5% auto;
  max-width: 60vw;
  position: relative;
  overflow: hidden;

  @media (max-width: $breakpoin480) { 
    margin: 10% auto;
   }
}
