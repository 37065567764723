.footer-serv{
  background-color: rgb(70, 70, 70);
  color: white;
 box-sizing: border-box;
 padding: 0 20vw;
text-align: center;
 h3, h4{
  margin: 0;
  &:first-child{
    padding-top: 10vh;
  }
  &:last-child{
    padding-bottom: 5vh;
  }
 }
}

.footer {
background-color: rgb(70, 70, 70);
  margin: 0;
  position: relative;
  width: 100vw;
  overflow: hidden;
  color: $whiteColor;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-size: var(--mainFont);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  //padding: 50px 200px 50px 100px;
  padding: 50px 100px;
  z-index: 9;
  @media (max-width: $breakpoin1024) { 
   padding: 50px !important;
  }

  .footer-logo{
    height: 15vh;
  }

  .contacts-inner {
    color: $blackColor;
    color: white;
    margin-top: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 100px;
    line-height: 27px;
    text-align: center;
    @media (max-width: $breakpoin1024) {
      line-height: 27px;
      font-size: calc(var(--mainFont) - 5px);
      text-align: center;
      gap: 15px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
 
    a {
      text-decoration: none;
      color: $blueColor;
      &:hover{
        color: $whiteColor;
      }
      svg{
      fill: $blueColor;
      height: 50px;
      width: 50px;
      transition: all .3s;
      &:hover{
        cursor: pointer;
        fill: $whiteColor;
      }
    }}
  }
  .legal-notice{
    text-align: center;
    padding: 1vh;
  }
  a {
    text-decoration: none;
    color: $blueColor;
    &:hover{
      color: $whiteColor;
      cursor: pointer;
    }}
}
