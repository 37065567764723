.particles{
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 6;
  overflow: hidden;
  .particle{
    border-radius: 50%;
    filter: blur(3px);
    position: absolute;
}
}

@keyframes move {
  0%{
      transform: translateX(0);
      opacity: 0;
  }
  10% ,90%{
      opacity: 1;
  }
  100%{
      transform: translateX(45vw);
      opacity: 0;
  }
}