//var(--mainFont): 30px;

:root {
  --mainFont: 25px;
}

//whiteTheme
$whiteColor: white;
$blackColor: black;
$boxShadow: #c4c7cc;
$textShadow: rgba(0, 0, 0, 0.3);
$blueColor: rgb(54, 169, 225);
$grayBack: rgb(227, 225, 225);
$borderTopLeft: rgba(255, 255, 255, 0.3);
$borderBottomRight: rgba(255, 255, 255, 0.5);
$secondaryColor: #bcdaf7;
$bodyColor: #ced5de;
$backGlass: rgba(196, 199, 204, 0.7);

$breakpoin2560: 2559.99px;
$breakpoin1920: 1921.99px;
$breakpoin1440: 1439.99px;
$breakpoin1024: 1023.99px;
$breakpoin768: 767.99px;
$breakpoin480: 479.99px;
$breakpoin360: 359.99px;
