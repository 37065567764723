.notice{

  padding: 5vw;
  @media (max-width: $breakpoin480) { 
   padding: 0;
  }

  h2{
    text-align: center;
    @media (max-width: $breakpoin480) { 
      font-size: 25px; 
    }
  }

ol {
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: li;
  margin-bottom: 20px;
  li{
margin-top: 15px;
margin-bottom: 15px;
    &:before {
  counter-increment: li;
  content: counters(li, ".") ". ";
  }
}
}
}