.card {
  margin-top: 10%;
  height: 380px;
  width: 270px;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  position: relative;
  background: -o-linear-gradient(315deg, $whiteColor, $backGlass);
  background: linear-gradient(135deg, $whiteColor, $backGlass);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
  border-top: 2px solid $borderTopLeft;
  border-left: 2px solid $borderTopLeft;
  border-right: 2px solid $borderBottomRight;
  border-bottom: 2px solid $borderBottomRight;
  -webkit-box-shadow: 5px 5px 5px $boxShadow;
  box-shadow: 5px 5px 5px $boxShadow;
  font-size: calc(var(--mainFont) - 5px);
  //z-index: -2;
  transition: all 1s;
  overflow: hidden;

  @media (max-width: $breakpoin2560) {
    margin-top: 5%;
  }
  @media (max-width: $breakpoin1920) {
    margin-top: 7%;
  }
  @media (max-width: $breakpoin1440) {
    margin-top: 5%;
  }
  @media (max-width: $breakpoin1024) {
    overflow-y: scroll;
    padding-bottom: 20px;
  }
  .card-face,
  .card-back {
    height: inherit;
    width: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

    .card-title {
      font-size: calc(var(--mainFont) + 5px);
      cursor: default;
    }
    .card-more {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 10px;
    }
  }
  .card-back {
    display: none;
    height: auto;
    width: auto;
    padding: 20px 40px;

    .card-title {
      font-size: calc(var(--mainFont) + 5px);
      cursor: default;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }

    .card-descr {
      margin: 0 20px;
      margin-bottom: 25px;
    }

    .back-buttons {
      @media (max-width: $breakpoin1024) {
        gap: 20px;
        padding: 0 40px 0 40px;
      }
    }
    @media (max-width: $breakpoin480) {
      padding: 20px 20px;
    }
    @media (max-width: 750px) {
      padding: 5px 5px;
    }
  }
}
.is-flipped {
  margin-top: 2%;
  height: 70vh;
  width: 50vw;

  @media (max-width: $breakpoin1440) {
    margin-top: 2.5% !important;
    height: 65vh !important;
    width: 40vw !important;
  }
  @media (max-width: $breakpoin1024) {
    width: 30vw !important;
  }
  @media (max-width: $breakpoin768) {
    width: 80vw !important;
  }
  @media (max-width: $breakpoin480) {
    height: 90vh !important;
  }
}
