.production {
  background: url('../pics/engineer.jpg');
  background-size: cover;
  z-index: 2;
  background-position: 100%;
display: flex;
align-items: center;
justify-content: center;

  // @media (max-width: $breakpoin480) { 
   
  //  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // }
  @media (max-width: $breakpoin768) { 
    flex-direction: column;
    justify-content: flex-start;
  }
  

  @media (max-width: 1439px) {
    background-position: 80%;
  }
  .text {
    cursor: default;
    margin-top: 5vh;
    margin-left: 40px;
    margin-right: 40px;
    //width: 100%;
    color: $whiteColor;
    background-color: rgba(70, 70, 70, .7);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(7px);
    border: 2px solid $blueColor;
    padding: 30px 25px;

    @media (max-width: $breakpoin1440) { 
     width: 50%;
    }
    @media (max-width: $breakpoin768) {
      width: 60%;
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: $breakpoin480) {
      width: 100%;
      padding: 0 10px;
    }

 

    h2{
      @media (max-width: $breakpoin480) { 
       font-size: calc(var(--mainFont + 20px));
      }

      @media (max-width: 360px) { 
       font-size: calc(var(--mainFont) + 1px);
      }
    }

    .options{
      color: black;
    }

    ul{
      list-style: none;
      padding: 0;
      li{
        margin-bottom: 15px;
      }
    }
  }
  
}

.install{
  background: url('../pics/install.jpeg');
  background-size: cover;
  background-position: 100%;
  z-index: 2;
}

.repair{
  background: url('../pics/gears.jpg');
  background-size: cover;
  z-index: 2;
  background-position: 100%;
}