.accordion-item {
  border-radius: 10px;
  background: $grayBack;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(7px);
  transition: all 1s;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .accordion-title {
    border-radius: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 20px;

    &:hover {
      background-color: $whiteColor;
    }
    @media (max-width: $breakpoin480) {
      text-align: start;
      font-size: calc(var(--mainFont) - 10px);
    }

    div{
      margin: 0 10px;
      @media (max-width: $breakpoin480) { 
       margin: 0;
      }
    }
  }

  .accordion-content {
    padding: 30px;
    display: none;
    visibility: hidden;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(7px);
    transition: max-height 0.5s ease-in-out;
    -moz-transition: height 0.5s ease-in-out;
    -webkit-transition: height 0.5s ease-in-out;
    -o-transition: height 0.5s ease-in-out;
    ol {
      @media (max-width: $breakpoin480) {
        padding: 0;
        padding-left: 10px;
      }
    }

    @media (max-width: $breakpoin480) {
      text-align: center;
      font-size: calc(var(--mainFont) - 10px);
    }
  }
}
.active {
  transition: all 1s;
}
