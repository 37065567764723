.tabs{
  ul {
  display: flex;
  list-style: none;
  margin: 0;
  margin-top: 5vh;
  justify-content: space-around;
  align-items: center;
  padding: 0;

  @media (max-width: $breakpoin480) {
    flex-direction: column;
    gap: 20px;
  }
  li {
    font-size: 18px;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid $blueColor;
    transition: all 0.5s;
    width: 30%;
    text-align: center;
    cursor: pointer;
    @media (max-width: $breakpoin480) { 
     width: 100%;
    }
    &:hover {
      background-color: #f1f1f1;
    }
    &.active {
      background-color: $blueColor;
    }
  }
}}