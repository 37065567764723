.preloader {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  // background: $blackColor;
  background: rgb(70, 70, 70);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $whiteColor;
  overflow: hidden !important;

  .texts-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 70px;
      height: 60px;
      width: 100%;
      font-size: 20px;
      overflow: hidden;
      font-weight: 800;
      opacity: 0;
      color: $blueColor;

      @media (max-width: $breakpoin768) { 
       width: 100%;
       justify-content: center;
       gap: 20px;
      }
      @media (max-width: $breakpoin480) { 
        font-size: 15px;
      }
  }
  img{
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%);
    height: 20vh;
    animation: hi 4s ease-in;
    opacity: 0;
    @media (max-width: $breakpoin768) { 
     bottom: 10%;
    }
  }
}