.button{
  border: 3px solid $blueColor;
  border-radius: 8px;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  color: black;
  display: flex;
    justify-content: center;
    align-items: center;

  &:hover {
    cursor: pointer;
    background-color: $blueColor;
    color: white;
  }
  &:visited {
    color: black;
  }

}

.request {
  color: $whiteColor;
  background-color: $blueColor;
}

.flex-row-space-around{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
  gap: 50px;

  @media (max-width: $breakpoin1024) { 
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
}

.center{
  text-align: center;
}

.hyper{
  display: inline-block;
  text-decoration: underline;
  border: none;
  border-radius: 0;
  transform: none;
  text-align:inherit;
  height: auto;
  width: auto;
  margin: 0;
  text-indent: 0;
  color: #0000FF;
  &:visited {
    color: #800080;
  }
  &:hover {
    cursor: pointer;
    background: transparent;
    color: #0000FF;
  }
}

.line-before{
  margin-top: 30px;
  margin-bottom: 10px;
  border-top: 3px solid lighten(#556270, 10%);
}

.flex-2-col{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-around;

  @media (max-width: $breakpoin1024) { 
   flex-direction: column;
  }
  .img1{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%) scale(-1, 1);
    z-index: -1;
    height: 40vh;

    @media (max-width: $breakpoin480) { 
      left: -40%;
    }
    @media (max-width: 350px) { 
      left: -60%;
    }
  }
  .img2{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%) ;
    z-index: -178;
    height: 40vh;
    @media (max-width: $breakpoin480) { 
     right: -40%;
    }
    @media (max-width: 350px) { 
      right: -60%;
    }
  }
}

.center-item{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col{
  display: flex;
  flex-direction: column;
}