.company-plus{
  background: url(../pics/pluses.jpeg);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;

  @media (max-width: $breakpoin480) { 
   justify-content: center;
  }

  .text {
    cursor: default;
    margin-top: 15vh;
    margin-right: 40px;
    width: 35vw;
    color: $whiteColor;
    background-color: rgb(70, 70, 70);
    border: 2px solid $blueColor;
    padding: 30px 25px;

    @media (max-width: $breakpoin1440) { 
     width: 50%;
    }
    @media (max-width: $breakpoin768) {
      width: 60%;
      margin-right: 0;
      margin-top: 0;
    }
    @media (max-width: $breakpoin480) {
      width: 100%;
    }
    @media (max-width: 360px) {
      padding: 0 10px;
    }
    h2{
      @media (max-width: $breakpoin480) { 
       font-size: calc(var(--mainFont + 20px));
      }

      @media (max-width: 360px) { 
       font-size: calc(var(--mainFont) + 1px);
      }
    }
    h3 {
      @media (max-width: $breakpoin480) { 
        font-size: calc(var(--mainFont + 20px));
       }
 
       @media (max-width: 360px) { 
        font-size: calc(var(--mainFont) );
       }
    }
  }
}